
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ClaimNatures from "@/views/reusable/claims/motor-insurance/claim-natures.json";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "motor-insurance-claims-edit-component",
  components: { Form, Field, ErrorMessage, ImagesUploader },
  props: ["publicId"],
  data() {
    return {
      icClass: "col-xl-12",
      ttgClass: "col-xl-4",
      hprClass: "col-xl-4",
      fbClass: "col-xl-6",
      hasFireBrigadeReport: false,
      hasPoliceReport: false,
      hasBeenTakenToGarage: false,
      hasLicence: false,
      hasThirdPartyInfo: false,
      hasGarageInfo: false,
      isALicensedDriver: false,
      //   publicId: this.$route.params.publicId,
      claimNatures: ClaimNatures,
      claimNature: "",
      itemPublicId: "",
      lossDate: "",
      todaysDate: "",
      locationOfIncident: "",
      policeReport: "",
      takenToGarage: "",
      licensedDriver: "",
      weatherCondition: "",
      accidentImage: "",
      descriptionOfIncident: "",
      thirdPartyFullName: "",
      thirdPartyPhoneNumber: "",
      thirdPartyEyeWitness: "",
      thirdPartyDriverLicense: "",
      thirdPartyInsuranceCertificate: "",
      thirdPartyOtherDocuments: "",
      acceptTerms: false,
      garageName: "",
      garagePhoneNumber: "",
      amount: "",
      amountImage: "",
      garageAddress: "",
      damagedVehicleImages: "",
      policy: "",
      policies: [],
      policyDetails: [],
      role: "",
      route: "",
      policyItemRoute: "",
      policyStartDate: "",
      currentTime: variables.currentTime(),
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const claimsValidator = Yup.object().shape({
      policy: Yup.mixed().required().label("Policy reference"),
      itemPublicId: Yup.mixed().required().label("Accidented vehicle"),
      claimNature: Yup.mixed().required().label("Accidented vehicle"),
      lossDate: Yup.date().required().label("Date and time of incident"),
      locationOfIncident: Yup.string().required().label("Location of incident"),
      descriptionOfIncident: Yup.string()
        .required()
        .label("Full description of incident")
      // damagedVehicleImages: Yup.mixed()
      //   .required()
      //   .label("Upload damaged vehicles"),
      // garagePhoneNumber: Yup.number()
      //   .min(11)
      //   .required()
      //   .label("Garage phone number"),
      // acceptTerms: Yup.boolean()
      //   .oneOf([true])
      //   .required()
      //   .label("Terms & conditions"),
    });

    const submitForm = (values, { resetForm }) => {
      console.log(values);
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (const [key, value] of Object.entries(values)) {
              if (Array.isArray(value)) {
                value.forEach((itemValue, index) => {
                  formData.append(
                    `${key}[${index}]`,
                    itemValue as string | Blob
                  );
                });
              } else {
                formData.append(key, value as string | Blob);
              }
            }

            ApiService.setHeader();
            ApiService.post(
              `${variables.CLAIMS_ROUTE}/${props.publicId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "client-claims-history"
                });
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return { submitButton1, claimsValidator, submitForm };
  },
  created() {
    const router = useRouter();
    const store = useStore();
    this.role = store.getters.currentUser.role;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Get claim information route based on user roles
    if (this.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = `${variables.CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.POLICIES_ROUTE}`;
    } else if (this.role == variables.BROKER_USER_ROLE) {
      // Broker role claim information route
      // this.route = `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}/${this.publicId}`;
      this.route = `${variables.CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.BROKER_CLIENT_POLICY_DETAILS_ROUTE}`;
    } else if (
      this.role == variables.ADMIN_USER_ROLE ||
      this.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      // Super Admin & Admin role claim information route
      this.route = `${variables.SUPERADMIN_CLIENT_CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE}`;
    }

    this.getCurrentDate();

    // Get the list of policies associated with this claim type
    this.getPolicies();

    // Get the associated claim information
    this.getClaimInformation();
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to variables
            this.claimNature = data.data.metadata.claimNature;
            this.policy = data.data.metadata.policy;
            this.getPolicyInformation(data.data.metadata.policy);
            this.itemPublicId = data.data.metadata.itemPublicId;
            this.lossDate = data.data.metadata.lossDate;
            this.amount = data.data.metadata.amount;
            this.locationOfIncident = data.data.metadata.locationOfIncident;
            this.licensedDriver = data.data.metadata.licensedDriver;
            this.weatherCondition = data.data.metadata.weatherCondition;
            this.descriptionOfIncident =
              data.data.metadata.descriptionOfIncident;
            this.garageName = data.data.metadata.garageName;
            this.thirdPartyFullName = data.data.metadata.thirdPartyFullName;
            this.thirdPartyPhoneNumber =
              data.data.metadata.thirdPartyPhoneNumber;
            this.thirdPartyEyeWitness = data.data.metadata.thirdPartyEyeWitness;
            this.thirdPartyDriverLicense =
              data.data.metadata.thirdPartyDriverLicense;
            this.thirdPartyInsuranceCertificate =
              data.data.metadata.thirdPartyInsuranceCertificate;
            this.thirdPartyOtherDocuments =
              data.data.metadata.thirdPartyOtherDocuments;
            this.garageAddress = data.data.metadata.garageAddress;
            this.garagePhoneNumber = data.data.metadata.garagePhoneNumber;
            this.takenToGarage = data.data.metadata.takenToGarage;
            this.policyStartDate = data.data.policyStartDate;
            // policeReport: "",
            // accidentImage: "",
            // amountImage: "",
            // damagedVehicleImages: "",
            if (data.data.lossType == "Accident") {
              this.hasLicence = true;
              this.hasGarageInfo = true;
              this.hasBeenTakenToGarage = true;
            } else if (data.data.lossType == "Fire Damage") {
              this.hasBeenTakenToGarage = true;
              this.hasGarageInfo = true;
              this.icClass = "col-xl-12";
              this.ttgClass = "col-xl-12";
            } else if (data.data.lossType == "Third-Party Incident") {
              this.hasPoliceReport = true;
              this.hasThirdPartyInfo = true;
              this.hasLicence = true;
            } else if (data.data.lossType == "Stolen(Theft)") {
              this.hasPoliceReport = true;
              this.icClass = "col-xl-12";
              this.hprClass = "col-xl-12";
            } else if (data.data.lossType == "Vandalised") {
              this.hasPoliceReport = true;
              this.hasBeenTakenToGarage = true;
              this.hasGarageInfo = true;
              this.hprClass = "col-xl-6";
              this.ttgClass = "col-xl-6";
            } else {
              this.defaultValues();
            }
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            console.log(error);
            router.go(-1);
          });
      }
    },
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/business-class/MOT`)
          .then(({ data }) => {
            //Assign data to props
            this.policies = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(value) {
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.policyItemRoute}/${value}/items`)
          .then(({ data }) => {
            //Assign data to variables
            this.policyDetails = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getClaimNature(event) {
      this.defaultValues();

      const code =
        event.target.options[event.target.selectedIndex].dataset.code;
      // If the claim nature is accident
      if (code == "AC") {
        this.hasLicence = true;
        this.hasBeenTakenToGarage = true;
      } else if (code == "FD") {
        this.hasBeenTakenToGarage = true;
        this.hasFireBrigadeReport = true;
        this.hasGarageInfo = true;
        this.icClass = "col-xl-12";
        this.ttgClass = "col-xl-6";
        this.fbClass = "col-xl-6";
      } else if (code == "TPI") {
        this.hasPoliceReport = true;
        this.hasThirdPartyInfo = true;
        this.hasLicence = true;
      } else if (code == "ST") {
        this.hasPoliceReport = true;
        this.icClass = "col-xl-12";
        this.hprClass = "col-xl-12";
      } else if (code == "VD") {
        this.hasPoliceReport = true;
        this.hasBeenTakenToGarage = true;
        this.hprClass = "col-xl-6";
        this.ttgClass = "col-xl-6";
      } else {
        this.defaultValues();
      }
    },
    getCurrentDate() {
      this.todaysDate = variables.currentDate();
    },
    getGarageChoice(event) {
      if (event.target.options[event.target.selectedIndex].value == "Yes") {
        this.hasGarageInfo = true;
      }
    },
    getLicenseChoice(event) {
      if (event.target.options[event.target.selectedIndex].value == "No") {
        this.isALicensedDriver = true;
      } else {
        this.isALicensedDriver = false;
      }
    },
    defaultValues() {
      this.icClass = "col-xl-12";
      this.ttgClass = "col-xl-4";
      this.hprClass = "col-xl-4";
      this.fbClass = "col-xl-6";

      this.hasPoliceReport = false;
      this.hasBeenTakenToGarage = false;
      this.hasLicence = false;
      this.hasThirdPartyInfo = false;
      this.hasGarageInfo = false;
      this.isALicensedDriver = false;
      this.hasFireBrigadeReport = false;

      this.takenToGarage = "";
      this.licensedDriver = "";
      this.weatherCondition = "";
      this.thirdPartyEyeWitness = "";
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumber(amount) {
      return variables.addCommaToNumber(amount);
    },
    acceptNumberOnly(amount) {
      return variables.acceptNumberOnly(amount);
    },
    policeReportelected(event) {
      this.policeReport = event.target.files[0];
    },
    checkTime(event) {
      const selectedTime = new Date(event.target.value).getTime();
      const maxTime = new Date(this.todaysDate).getTime();

      if (selectedTime > maxTime) {
        event.target.value = this.todaysDate;
        this.lossDate = event.target.value;
      }
    },
  }
});
